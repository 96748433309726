import React from 'react';
import Layout from '../components/Layout';
import { Section } from '../components/Section';
import Container from '../components/Container';
import Link from '../components/Link';

const NotFoundPage = () => (
  <Layout>
    <Section>
      <Container>
        <h1>Stránka nenalezena</h1>
        <p>Naše chaloupka existuje, ale tato stránka ne.</p>

        <p>
          <Link to="/">Zkuste to z hlavní strany</Link>
        </p>
      </Container>
    </Section>
  </Layout>
);

export default NotFoundPage;
